* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  position: unset !important;
  color: #000000;
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)) !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.section-space {
  padding: 90px 0px;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  word-break: normal;
}
.effect-box-left-right {
  animation: movebounce 2s linear infinite;
}

.effect-box-top-bottom {
  animation: movebouncetopbottom 2s linear infinite;
}

@keyframes movebounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes movebouncetopbottom {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(20px);
  }
  to {
    transform: translateX(0);
  }
}

.fixed-con {
  z-index: 9724790009779558 !important;
  background-color: #f7f8fc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  z-index: 9724790009779 !important;
  top: 0;
  left: unset;
  right: -5px;
  display: none !important;
  border-radius: 50%;
  border: 2px solid gold;
}
.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  width: 80px;
  height: 80px;
}
/*hide google translate link | logo | banner-frame */
.goog-logo-link,
.gskiptranslate,
.goog-te-gadget span,
.goog-te-banner-frame,
#goog-gt-tt,
.goog-te-balloon-frame,
div#goog-gt- {
  display: none !important;
}
.goog-te-gadget {
  color: transparent !important;
  font-size: 0px;
}

.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

/*google translate Dropdown */

#google_translate_element select {
  background: transparent;
  color: #000000;
  border: none;
  border-radius: 0;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  margin: 0 !important;
  width: 170px;
}

#google_translate_element select:focus-visible {
  border: none;
  outline: none;
}

.goog-te-gadget-simple {
  background-color: #ecebf0 !important;
  border: 0 !important;
  font-size: 10pt;
  font-weight: 800;
  display: inline-block;
  padding: 10px 10px !important;
  cursor: pointer;
  zoom: 1;
}

/*google translate Dropdown */
.skiptranslate iframe {
  display: none;
}
.MuiDialog-paperScrollPaper{
  overflow: hidden;
}
.tablet > div > .MuiDialog-paperScrollPaper{
  top: 80px;
}
.center-btn {
  position: relative;
  text-align: center;
  margin-top: 20px
}